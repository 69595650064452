.flippable-card-container .card-front, .flippable-card-container .card-back {
  /* border-radius: 4px; */
    /* border: 1px solid #972EFF; */
    border-radius: 10px;
}
.flippable-card-container .card-back {
  border: 4px solid #972EFF;
  /* border-width: 4px; */
}
.flip-exit-done, .flip-enter-done {
  /* border: 4px solid #972EFF; */
}
.flippable-card-container > .card {
    /* Sizing */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: none;
    background-color: inherit;

    font-family: 'Satoshi-Black';
    
    transform-style: preserve-3d;
  
    /* Content Alignment */
    position: relative;
  
    /* Styling */
    border-radius: 5px;
    /* border: 5px solid white; */
    /* border: 5px solid #972EFF; */
    /* border: 4px solid #972EFF; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  
    user-select: none;
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;

  }
.flippable-card-container > .card:hover {
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7); */
  scale: 1.05;
  /* border-width: 1px; */
}
  
  .card-front,
  .card-back {
    /* Sizing */
    height: 100%;
    width: 100%;
  
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  
    /* Alignment */
    position: absolute;
  
    /* Content Alignment */
    display: flex;
    justify-content: center;
    align-items: center;
  
    /* Content Styling */
    font-weight: 800;
    font-size: 40px;
    color: white;
    /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9); */
  }
  
  .card-front {
    /* background-color: #73d0ff; */
    /* background-color: #F4438C; */
    background-color: #972EFF;
    /* background-color: #6574FF; */
    /* background-color: #EC5646; */
  }
  
  .card-back {
    background-color: #EC5646;
    /* background-color: #972EFF; */
    /* background-color: #ffad66; */
    transform: rotateY(180deg);
    font-size: 20px;
    padding: 5px 7px;
  }
  