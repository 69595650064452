
/* background-color: #F4438C; */
/* background-color: #972EFF; */
/* background-color: #6574FF; */
/* background-color: #EC5646; */
/* background-color: rgb(244, 67, 140); */
/* background-color: rgb(151, 46, 255); */
/* background-color: rgb(101, 116, 255); */
/* background-color: rgb(236, 86, 70); */

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./fonts/Satoshi-Variable.woff2') format('woff2'),
       url('./fonts/Satoshi-Variable.woff') format('woff'),
       url('./fonts/Satoshi-Variable.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('./fonts/Satoshi-VariableItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-VariableItalic.woff') format('woff'),
       url('./fonts/Satoshi-VariableItalic.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Light';
  src: url('./fonts/Satoshi-Light.woff2') format('woff2'),
       url('./fonts/Satoshi-Light.woff') format('woff'),
       url('./fonts/Satoshi-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('./fonts/Satoshi-LightItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-LightItalic.woff') format('woff'),
       url('./fonts/Satoshi-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Regular';
  src: url('./fonts/Satoshi-Regular.woff2') format('woff2'),
       url('./fonts/Satoshi-Regular.woff') format('woff'),
       url('./fonts/Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Italic';
  src: url('./fonts/Satoshi-Italic.woff2') format('woff2'),
       url('./fonts/Satoshi-Italic.woff') format('woff'),
       url('./fonts/Satoshi-Italic.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./fonts/Satoshi-Medium.woff2') format('woff2'),
       url('./fonts/Satoshi-Medium.woff') format('woff'),
       url('./fonts/Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('./fonts/Satoshi-MediumItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-MediumItalic.woff') format('woff'),
       url('./fonts/Satoshi-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./fonts/Satoshi-Bold.woff2') format('woff2'),
       url('./fonts/Satoshi-Bold.woff') format('woff'),
       url('./fonts/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('./fonts/Satoshi-BoldItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-BoldItalic.woff') format('woff'),
       url('./fonts/Satoshi-BoldItalic.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Black';
  src: url('./fonts/Satoshi-Black.woff2') format('woff2'),
       url('./fonts/Satoshi-Black.woff') format('woff'),
       url('./fonts/Satoshi-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('./fonts/Satoshi-BlackItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-BlackItalic.woff') format('woff'),
       url('./fonts/Satoshi-BlackItalic.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: italic;
}


/* * {
border: 1px solid red;
} */
.App .row {
margin: 0;
}
.App {
  
  color: white;
text-align: center;
/* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, din-round */
/* font-family: din-round, sans-serif; */
/* font-family: "Noto Serif"; */
font-family: 'Satoshi-Light';
  background-color: #07071b;
  /* background-color: white; */
}
.prize-page td, .prize-page th {
  /* font-family: "Noto Serif"; */
  padding: 2px 10px;
  font-size: 12px;
}
.prize-page .payout-table {
  width: 100%;
  /* background-color: linear-gradient(to bottom top, blue, red);; */
  /* background-image: linear-gradient(to bottom, rgb(99, 190, 123), rgb(255, 255, 255)); */
}
.payout-table .prize-row {
  opacity: 0.925;
}
.payout-table-header {
  background-color: #333;
  color: white;
}
.payout-header {
  padding: 10px;
}
.payout-table .prize-row:hover {
  opacity: 1;
  cursor: pointer;
}
.payout-container {
  border-radius: 4px;
  border: 1px solid #ccc;
}
.payout-description-text {
  text-align: left;
}
.payout-detail-container {
  margin: 0;
  height: 504px;
}
.payout-header {
  font-size: 18px;
  /* background-color: ; */
}
.create-pool-button {
padding-bottom: 20px;
}
.trails-modal > .modal-dialog {
margin: 0;
width: 100vw;
height: 100vh;
/* max-width: 100%; */
}
.trails-modal > .modal-dialog > .modal-content {
width: 100vw;
height: 100vh;
border-radius: 0;
}
.trails-modal .modal-body {
width: 100%;
max-width: 850px;
margin: auto;
padding: 50px 20px 0;
/* padding: 50px 350px 0; */
}
.modal-main-image {
width: 100%;
height: 150px;
/* background-color: rgb(238	126	48	); */
background-color: #ee7e31;
padding: 10px;
}
.modal-main-image img {
height: 130px;
}
/* .modal-main .card {
border-radius: 10px;
} */
.modal-main .slideshow img {
/* max-height: 100%; */
max-width: 100%;
  align-items: center;
  justify-content: center;
display: flex;
border-radius: 20px;
/* height: 300px; */
max-height: 400px;
/* max-width: 400px; */
/* max-width: 100%; */
/* border-top-right-radius: 9px; */
/* border-top-left-radius: 9px; */
}
.modal-main .slideshow {
display: flex;
  align-items: center;
  justify-content: center;
}
.modal-main .slideshow.middle img {
height: 400px;
margin: auto;
}
.modal-main .slideshow.middle .card-text {
text-align: center;
}
.slideshow input {
height: 50px;
}
.slideshow button.btn {
width: 100%;
background-color: none;
background-image: none;
}

.trails-modal .btn:disabled:not(.btn-success) {
opacity: 0.25;
transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .05s;
}

.trails-modal .btn:hover {
opacity: 1;
transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .35s;
}
.trails-modal .btn {
background-image: linear-gradient(to right, rgb(120	169	189	) , rgb(183	225	139	));
border: none;
color: white;
padding: 10px 70px;
font-weight: 900;
opacity: 0.8;
}

.trails-modal .btn.inner > div {
background-color: white;
padding: 7px 67px;
/* color: transparent; */
border-radius: 0.375rem;
}
.trails-modal .btn.inner > div > div {
background: -webkit-linear-gradient( rgb(120	169	189	) , rgb(183	225	139	));
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.trails-modal .btn.inner {
/* border: none; */
/* color: white; */
padding: 2px;
/* font-weight: 900; */
/* opacity: 0.8; */
/* background-color: rgb(180	222	223	); */
/* background-color: rgb(120	169	189	);
color: white; */
}
.admin-header-outer {
padding-bottom: 10px;
}
.admin-header {
font-size: 32px;
border-bottom: 1px solid #ddd;
/* color: rgb(119,119,119); */
/* color: #555; */
}
.admin-header-image {
float: left;
height: 42px;
border-radius: 42px;
}
.token-toggle {
padding-bottom: 20px;
}
.token-toggle > label {
width: 150px;
}
.token-amount-input {
min-width: 90px;
}
#dropdown-tokens {
background-image: linear-gradient(to right, rgb(120	169	189	) , rgb(183	225	139	));
border: none;
padding: 10px 25px;
font-size: 20px;
}
#dropdown-tokens > img {
height: 25px;
margin-top: -2px;
}
#dropdown-tokens > span {
padding: 0 10px;
}
.token-dropdown .dropdown-toggle::after {
float: right;
  margin-top: 10px;
}
.base-dropdown > button {
  padding: 6px 0 6px 20px;
  /* width: 200px; */
}
.base-dropdown > div {
  /* width: 200px; */
}
.base-dropdown {
  padding: 0 10px;
}
.dropdown-col {
  display: inline-block;
  padding: 0px 10px 5px;
  width: 250px;
}
.dropdown-tokens img {
height: 35px;
border-radius: 25px;
}
.dropdown-tokens .dropdown-item {
padding: 5px 20px;
color: #444;
}
.dropdown-tokens span {
padding: 0 20px;
  font-size: 20px;
  font-weight: 100;
}

.trails-modal .modal-footer {
padding: 35px;
}
.modal-footer > .row {
padding: 0 15%;
}
.trails-modal .modal-footer .row {
width: 100%;
}
.trails-modal .modal-footer .col {
display: flex;
  align-items: center;
  justify-content: center;
}
.card-text {
padding: 10px;
font-size: 20px;
}
.card-text ul {
display: inline-block;
  text-align: left;
}
.trailhead {
background-color: #c4ab92;
color: white;
padding: 10px;
/* margin: 0 0 0 -70px; */
border-radius: 70px;
border-bottom-left-radius: 0px;
}
.trails-green-background {
background-color: rgb(184, 226, 138);
}
.trails-green-background > .progress-bar {
background-color: rgb(184, 226, 138);

/* background-image: linear-gradient(to right, rgb(120	169	189	) , rgb(183	225	139	)); */
/* background-color: rgb(180	222	223	); */
/* background-color: rgb(33	49	15	); */
color: rgb(33	49	15	);
font-weight: 900;
}
.modal-main {
/* padding: 30px 0 0; */
padding: 0;
}
.modal-main-header {
font-weight: 900;
  font-size: 28px;
  width: 100%;
  text-align: center;
padding: 40px;
}
.trails-modal .progress {
height: 22px;
}
.trail, .trail > .inner {
height: 190px;
width: 100%;
}
.flex-container {
display: flex;
}
.flex-child-1 {
  flex: 1;
} 
.flex-child-10 {
  flex: 12;
}  
.flex-child:first-child {
  margin-right: 20px;
}
.trail {
position: relative;
}
.trail.odd .marker {
left: 45%;
bottom: 0px;
position: absolute;
}
.trail.odd .inner {
width: 70%;
}
.trail.even {
width: 70%;
left: 30%;
}
.trail.even .marker {
right: 45%;
bottom: 0px;
position: absolute;
}
.trail .dotted {
height: 78px;
border-left: 2px dashed #aaa;
}
.trail.odd .dotted {
height: 58px;
border-left: 2px dashed #aaa;
}
.trail .button:not(.locked):hover {
cursor: pointer;
/* opacity: 1; */
transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .35s;
/* transform: scale(1.1); */
}
.trail .button .icon {
display: table-cell;
vertical-align: middle;
color: transparent;  
text-shadow: 0 0 0 white;
margin: auto;
font-size: 32px;
height: 60px;
width: 60px;
}
.trail .button > a {
height: 60px;
width: 60px;
}
.trail.even .button {
bottom: 80px;
}
/* .trail.even .react-tooltip {
bottom: 80px;
} */
.trail .button {
display: table;
text-align: center;
vertical-align: middle;
position: absolute;
bottom: 60px;
left: -28px;
height: 60px;
width: 60px;
border-radius: 30px;
/* background-color: rgb(184, 226, 138); */
background-color: #b8e28a;
/* border-left: 2px dashed #aaa; */
}
.trail.odd .inner {
position: absolute;
border-left: 30px solid #c4ab92;
border-bottom: 30px solid #c4ab92;
border-top: 30px solid #c4ab92;
border-bottom-left-radius: 70px;
border-top-left-radius: 70px;
padding-right: 30px;
/* right: 70px; */
/* margin-top: -30px; */
}
.trail.even .inner {
border-top-right-radius: 70px;
border-bottom-right-radius: 70px;
margin-top: -30px;
border-right: 30px solid #c4ab92;
border-top: 30px solid #c4ab92;
border-bottom: 30px solid #c4ab92;
}

.trail.c .inner, .trail.e .inner {
margin-top: -30px;
}
.trail.f .inner {
margin-top: -60px;
}
.trail.a .inner {
border-top: none;
border-top-left-radius: 0;
}
.trail.d .inner {
margin-top: -60px;
}

.bold {
font-weight: 900;
}
.xp {
opacity: 0.85;
font-weight: 100;
}
.align-left {
text-align: left;
}
img.avatar {
padding: 0;
}
.avatar {
width: 40px;
height: 40px;
border-radius: 40px;
padding: 10px;
display: inline-block;
text-align: center;
color: white;
}
.avatar-big {
  width: 70px;
  height: 70px;

}
.leaderboard-page {
height: 700px;
border-radius: 10px;
/* background-color: rgb(96	42	32	); */
background-color: rgb(89	55	49	);
padding: 10px;
/* background: url('assets/clipboard-outline-filled.svg'); */
/* background: url('assets/clipboard-outline-filled.png'); */
/* background-repeat: no-repeat; */
/* background-size: cover; */
}
.leaderboard-tabs .col {
color: white;
padding: 10px 0;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
cursor: pointer;
}
.leaderboard-tabs .active {
background-color: rgb(221	175	118	);
}
.leaderboard-tabs :not(.active) {
border-bottom-left-radius: 5px;
}
.leaderboard-tabs :not(.active):hover {
color: rgb(221	175	118	);
}
.leaderboard-page-outer-1 {
padding: 19px 24px 24px 24px;
/* border-radius: 10px; */
height: 636px;
width: 100%;
/* background-color: rgb(177	139	98	); */
/* background-color: rgb(237	208	155	); */
background-color: rgb(221	175	118	);
/* background-color: rgb(236	209	153	); */
}
.leaderboard-page-outer-2 {
padding: 12px;
border-radius: 10px;
height: 100%;
width: 100%;
/* background-color: rgb(96	42	32	); */
background-color: rgb(89	55	49	);
}
.leaderboard-page-outer-3 {
/* padding: 20px; */
/* border-radius: 10px; */
background-color: white;
height: 100%;
width: 100%;
overflow-y: scroll;
  /* display: block; */
}
.leaderboard-page table {
padding: 50px;
}
.leaderboard-page table tr {
height: 50px;
border-bottom: 1px solid rgb(150 202 195 );
}
.leaderboard-page table td {
vertical-align: middle;
}
.leaderboard-page table span.username {
padding-left: 20px;
font-weight: 400;
}
.leaderboard-page table {
max-width: 600px;
margin: auto;
}
.rocket {
max-width: 100%;
max-height: 400px;
padding: 30px 20%;
}
.expeditions-page {
/* color: #315274; */
color: rgb(149	202	195	);
}
.launching-soon {
font-weight: 900;
  font-size: 48px;
  letter-spacing: 6px;
}
.settings-page .user {
padding-left: 30px;
display: inline-block;
text-align: left;
}
.user-header {
font-weight: 900;
font-size: 28px;
}
.user-subheader {
font-weight: 100;
font-size: 12px;
color: #999;
padding-bottom: 20px;
}
.pencil {
background-color: #53adf0;
vertical-align: top;
  position: relative;
  display: inline;
  left: 105px;
  color: white;
  border-radius: 30px;
  padding: 3px 5px 6px 6px;
cursor: pointer;
}
.settings-header {
padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.settings-page img.icon {
padding: 0;
border: none;
}
.settings-page .icon {
height: 120px;
width: 120px;
border-radius: 120px;
text-align: center;
vertical-align: top;
font-size: 45px;
padding: 25px;
display: inline-block;
color: #888;
border: 1px dashed #888;
}
.pfp-input {
display: none;
}
body, html {
  background-color: #07071b;
  padding: 0;
}
.level-complete-outer {
margin-top: 15%;
/* position: relative; */
/* margin: auto; */
}
.level-complete {
font-size: 14px;
text-align: center;
height: 200px;
width: 200px;
background-color: #eab815;

background-color: rgb(28, 176, 246);
background-color: rgb(236, 166, 58 );
background-color: #ee7e31;
/* background-color: rgb(184, 226, 138); */
/* background-color: #b8e28a; */

border-radius: 200px;
/* text-transform: uppercase; */
color: white;
position: relative;
margin: auto;
}
.hidden > .level-complete-text {
display: none;
width: 0;
}
.hidden > #success-timeline {
display: none;
width: 0;
}
.level-complete-text {
text-align: center;
position: absolute;
bottom: 20px;
font-size: 14px;
width: 200px;
}
.burst-button {
width: 250px;
height: 50px;
background-color: #CBDC41;
cursor: pointer;
/* margin: 50px auto 0; */
position: relative;
overflow: hidden;
transition: .3s;
}
.burst-button:hover {
  transition: .3s;
  box-shadow: 0px 8px 11px 0px rgba(0,0,0,0.1);
}
#success-timeline, #incorrect-timeline {
width: 100px;
height: 100px;
font-size: 48px;
position: absolute;
left: 50%;
transform: translate(-50%, -50%);
top: 50%;
}

.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.fade-button {
  transition: opacity 1s ease;
}

.button {
margin: 0;
}
.error-text {
color: #dc3545;
opacity: 0.9;
padding-top: 10px;
font-size: 12px;
height: 28px;
text-align: center;
}
.card-body > .back-text {
padding: 10px;
}
.card-body > .back-text > a {
font-weight: 900;
  font-size: 16px;
text-decoration: none;
/* color: #ffc107; */
/* color: rgb(248, 213, 72); */
color: rgb(236, 166, 58 );
}

.hidden {
opacity: 0;
  transition: opacity 1s ease;
height: 0;
}
.not-hidden {
opacity: 1;
  transition: opacity 0.1s ease !important;
}
.btn:disabled {
transition: opacity 4s ease;
}
.highlight {
/* background-color: rgba(236, 166, 58, .2 ); */
background-color: #eee;
}

.text {
/* width: 100%;
text-align: center;
color: #fff; */
letter-spacing: 6px;
/* top: 50%;
transform: translateY(-50%);
position: absolute;
display: inline-block; */
}
.left-bar-socials {
position: absolute;
bottom: 0;
/* display: flex; */
padding-bottom: 12px;
padding: 0 5px 12px;
width: 100%;
margin-left: -10px;
}
.left-bar-socials img {
height: 35px;
-webkit-filter: grayscale(100%);
filter: grayscale(100%);
transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .35s;
padding: 7px 0;
}
.left-bar-socials img:hover {
-webkit-filter: unset;
filter: unset;
}
.left-bar-header {
font-size: 36px;
letter-spacing: 6px;
/* color: #DC1FFF; */
/* color: #5d3d28; */
/* color: #f7e6cd; */
/* color: rgb(100, 82, 70); */
/* color: rgb(164, 140, 113); */
/* color: #795c34; */
/* color: #80471c; */
/* color: rgb(110 189 100 ); */
/* color: rgb(184	226	138	); */
color: rgb(28, 176, 246);;
/* color: #9a7b4f; */
  padding: 30px 0 30px 16px;
font-weight: 900;
}
/* col-3 col-sm-2 col-md-4 col-lg-3 col-xl-2 */
.col-3 > .left-bar {
}
@media (min-width: 1200px) {
  .col-xl-2 > .left-bar {
      flex: 0 0 auto;
      width: 16.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg-3 > .left-bar {
      flex: 0 0 auto;
      width: 25%;
  }
}
@media (min-width: 768px) {
  .col-md-4 > .left-bar {
      flex: 0 0 auto;
      width: 33.33333333%;
  }
}
@media (min-width: 576px) {
  .col-sm-2 > .left-bar {
      flex: 0 0 auto;
      width: 16.66666667%;
  }
}
/* .col-3 > .left-bar {
    flex: 0 0 auto;
    width: 25%;
} */

.left-bar {
/* left: 0; */
/* top: 0; */
  /* position: fixed!important; */
  /* z-index: 1; */
border-right: 1px solid rgb(229,229,229);
  height: 100vh;
padding: 0 10px;
position: fixed;
left: 0;
top: 0;
  /* height: 100%; */
  overflow-y: auto;
  /* padding: 10px 16px 0; */
}
.left-bar-div {
  display: inline;
}
.left-bar-div-inner {
  height: 30px;
  width: 50px;
  padding-right: 20px;
  margin: auto;
}
.left-bar-item > span {
  /* line-height: 50px; */
  top: 50%;
  margin-top: -20px;
}
.left-bar-div > div {
  display: inline-block;
}
.left-bar-text {
  margin-top: -20px;
  position: relative;
  vertical-align: middle;
}
.left-bar-logo {
  max-height: 100%;
  max-width: 100%;
  display:block;
  margin:auto;
}

.left-bar-item:hover {
background-color: rgb(247,247,247);
cursor: pointer;
}

.left-bar-item {
letter-spacing: 2px;
text-align: left;
padding: 10px;
border-radius: 12px;
color: rgb(119,119,119);
font-size: 15px;
  font-weight: 700;
  /* letter-spacing: .8px; */
  /* text-transform: uppercase; */
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.active > .left-bar-item {
color: rgb(28, 176, 246);
border: 2px solid rgb(132,216,255);
background-color: rgb(221,244,255);
}

.left-bar-list {
box-sizing: content-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.main {
margin: 0 auto;
  max-width: 1312px;
  padding-top: 32px;
  padding-left: 256px;
  width: 100%;
}
.main > .row > .middle-col {
padding: 0 25px 25px 50px;
}
.main > .row > .right-col {
padding: 25px;
}

@media (min-width: 1500px) {
.main > .row > .middle-col {
  padding-left: 0px;
}
.main > .row > .middle-col {
  padding: 0 50px 25px 0px;
}
}
@media (max-width: 767px) {
.left-bar-logo {
  padding-right: 0;
}
.left-bar-list {
  padding-top: 50px;
}
.left-bar-item {
  text-align: center;
}
.trails-modal .modal-footer {
  padding: 15px 0;
}
.modal-footer > .row {
  padding: 0;
}
.trails-modal .btn.inner > div, .trails-modal .btn {
  padding: 10px 45px;
}
.leaderboard-page-outer-1, .leaderboard-page-outer-2 {
  padding: 0;
}
.modal-main .slideshow.middle img {
  height: 300px;
}
.modal-main-header {
  padding: 15px;
}
.leaderboard-page {
  height: 75vh;
}
.leaderboard-page table span.username {
  display: none;
}
}

.right-panel-panel {
border: 2px solid rgb(229,229,229);
  border-radius: 16px;
  padding: 24px;
}
.right-panel-img {
max-width: 100%;
}
.right-panel-header {
height: 70px;
}
.right-panel-header .xp {
width: 20%;
background-color: gold;
height: 48px;
display: inline-block;
border-radius: 4px;
}
.wallet-adapter-dropdown, .wallet-adapter-button {
float: right;
/* width: 175px; */
width: 100%;
}

.loading-img {
max-height: 250px;
}
.middle-panel {
padding: 24px;
}
.icon {
width: 100%;
}
.cards > .col-3 {
padding: 12px;
}
.cards .card:hover .icon {
/* .cards .card:hover { */
cursor: pointer;
opacity: 0.25;
transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .35s;
/* transform: scale(1.01); */
}

.cards .card {
border: none;
opacity: 0.95;
border-radius: 150px;
/* border-top-right-radius: 13px !important; */
/* border-top-left-radius: 13px !important; */
}
.cards .card .icon {
/* border-top-right-radius: 12px; */
/* border-top-left-radius: 12px; */
border-radius: 150px;
}
.cards .card .img {
border-radius: 150px;
background-color: #282828;
display: flex;
justify-content: center;
align-items: center;
/* background-color: #282828; */
/* border-top-right-radius: 13px !important; */
/* border-top-left-radius: 13px !important; */
}
.cards .card .project-name {
/* display: flex; */
/* justify-content: center; */
/* align-items: center; */
/* position: absolute; */
/* color: black; */
color: white;
padding: 0 5px;
opacity: 0;
/* bottom: 30%; */
/* flex: 0 0 120px; */
margin: auto;
position: absolute;
max-width: 100%;
/* height: 100%; */
/* top: 0; left: 0; bottom: 0; right: 0; */
}
.cards .card .img:hover .project-name {
opacity: 1;
}

.left-bar-list > a {
text-decoration: none;
color: inherit;
}

.trail .button.gray {
background-color: #999;
opacity: 0.7;
}
.animated-container > div {
margin: auto;
}
.xp-card.changing {
background-color: #a7ee91;
}
.xp-card {
position: fixed;
top: 10px;
right: 10px;
background-color: #e9e9e9;
color: black;
border-radius: 10px;
padding: 5px 20px;
text-align: center;
min-width: 100px;
z-index: 99999;
}
.xp-card > span {
transition-property: background-color;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .2s;
}
.small-text {
font-size: 10px;
font-weight: 100;
}
.back-arrow {
float: left;
cursor: pointer;
margin-left: 20px;
}
.back-arrow > a, .back-arrow > a:hover {
color: white;
text-decoration: none;
}
.forward-arrow {
float: right;
margin-right: 20px;
opacity: 0;
}
.inner-ring {
background-color: white;
border-radius: 200px;
}
.outer-ring {
padding: 7px;
/* border: 2px solid #eee; */
/* border: 5px solid rgb(150	202	194	); */
/* border: 5px solid #282828; */
border-radius: 200px;
/* background: radial-gradient(circle at top, #fff, #fff 0%, #eee, #eee 10%, rgb(150	202	194	) 100%, rgb(150	202	194	) 100%); */
/* background: radial-gradient(circle at top, #fff, #fff 0%, #ccc, #ccc 20%, #999, #999 40%); */
/* background-image: linear-gradient(to right, transparent 50%, #655 0); */
/* background-image: linear-gradient(to right, transparent 50%, rgb(150	202	194	) 0); */
/* background-image: linear-gradient(to right, transparent 50%, rgb(236	208	156	) 0); */
/* background-image: linear-gradient(to right, transparent 50%, rgb(191	191	191	) 0); */
/* background-image: linear-gradient(to right, transparent 50%, #ddd 0); */
/* background-image: linear-gradient(to right, transparent 50%, #ddd 0); */
}
@property --p{
syntax: '<number>';
inherits: true;
initial-value: 0;
}

.pie {
--p:20;
--b:10px;
--c:rgb(150	202	194	);
--w:150px;

width:var(--w);
aspect-ratio:1;
position:relative;
display:inline-grid;
margin:5px;
place-content:center;
/* font-size:25px; */
/* font-weight:bold; */
}
.pie:before,
.pie:after {
content:"";
position:absolute;
border-radius:50%;
}
.pie:before {
inset:0;
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
-webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));

}
.pie.pct_0:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(0*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_1:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(10*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_2:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(20*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_3:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(30*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_4:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(40*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_5:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(50*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_6:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(60*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_7:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(70*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_8:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(80*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_9:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(90*1%),#0000 0);
background-size:0 0,auto;
}
.pie.pct_10:before {
background:
  radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(100*1%),#0000 0);
background-size:0 0,auto;
}
.pie:after {
inset:calc(50% - var(--b)/2);
background:var(--c);
transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}
.animate {
animation:p 1s .5s both;
}
.no-round:before {
background-size:0 0,auto;
}
.no-round:after {
content:none;
}
@keyframes p {
from{--p:0}
}
.accordion-body {
text-align: left;
}
.frontier-page {
text-align: left;
}
.frontier-page .frontier-bullets {
padding-top: 20px;
font-size: 18px;
}
.frontier-page .frontier-bullets > div > span {
padding-right: 10px;
font-size: 20px;
}
.frontier-bullets > div {
padding: 4px 0;
font-size: 14px;
}
.frontier-page img {
width: 100%;
  border-radius: 10px;
max-width: 600px;
}
.username-letter {
margin-top: -2px;
  /* display: table; */
  text-align: center;
  width: 100%;
}
.timer {
  /* position: relative; */
  /* padding: 10px 0; */
  /* left: 50%; */
  /* transform: translate(-50%); */
  margin: 0 auto;
  /* top: 140px; */
  /* color: black; */
  /* color: rgb(216, 32, 109); */
  letter-spacing: 1px;
  text-align: center;
}
.timer .big-text {
  font-size: 30px;
  color: #EC5646;
  /* font-weight: 900; */
  /* line-height: 0.9; */
}
.timer .small-text {
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 1px;
  color: #ffdbd3;
  /* color: rgba(255, 255, 255, 0.5); */
}
.top-row-outer {
  height: 225px;
  width: 225px;
  border-radius: 25px;
  padding: 10px 0 35px;
  margin: auto;
}
.top-row-outer img {
  height: 150px;
  width: 150px;
  border-radius: 150px;
}

/* background-color: #F4438C; */
/* background-color: #972EFF; */
/* background-color: #6574FF; */
/* background-color: #EC5646; */
.top-row-outer.place_0 {
  background-color: #F4438C;
}
.top-row-outer.place_1 {
  background-color: #972EFF;
}
.top-row-outer.place_2 {
  background-color: #6574FF;
}
.medals-row {
  padding: 20px 0 30px;
}
.top-row-inner {
  padding: 0 0 7px;
}
.top-row-outer .username {
  font-weight: 900;
  font-size: 20px;
}
.top-row-outer .username {
  font-weight: 900;
}
.row > .user-col {
  margin: auto;
}
.user-col span.username {
  padding-left: 20px;
}
.user-row, .user-row .row {
  width: 100%;
}
.score-page {
  padding: 0 20px;
}
.score-page .accordion-body {
  padding: 0;
  max-height: 400px;
  overflow-y: scroll;
}
.score-page .accordion-body > .row {
  /* border-bottom: 1px solid #ddd; */
  /* border-bottom: 1px solid rgba(255, 255, 255, .05); */
  border: none;
}
.score-page .accordion-body > .row:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.score-page .accordion-body > .row > .col {
  padding: 10px 25px;
}
.accordion-header-row {
  padding: 20px 60px 5px 20px;
  font-family: 'Satoshi-Light';
  color: rgba(255, 255, 255, 0.75);
}
.team-name {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.team-name span {
  padding-left: 15px;
}
.register-panel {
  max-width: 600px;
  margin: auto;
}
.register-panel-header {
  font-size: 20px;
  padding: 0 10px 10px;
}
.prizes-row-outer {
  /* background: url('./assets/assets/background-3.png');
  background-repeat: no-repeat;
  background-size: cover; */
  padding: 0 0 20px;
  color: white;
}
.prizes-row-inner > div {
  padding: 5px;
}
.prizes-row-block {
  border-radius: 10px;
  padding: 10px 0;
}
.prizes-row-block {
  font-family: 'Satoshi-Black';
  font-size: 24px;
}
.prizes-row-block .header {
  font-size: 20px;
  font-weight: 100;
  font-family: 'Satoshi-Light';
  letter-spacing: 1px;
}

.prizes-row-block-1 {
  background-image: linear-gradient(to right, #F4438C, #972EFF);
}
.prizes-row-block-2 {
  background-image: linear-gradient(to right, #972EFF, #6574FF);
}
.prizes-row-block-3 {
  background-image: linear-gradient(to right, #6574FF, #EC5646);
}
.prize-descriptions {
  position: relative;
  /* color: black; */
  background-color: rgba(255, 255, 255, .02);
  border-radius: 20px;
  padding: 20px;
}
.prize-descriptions-row .prize-descriptions-col {
  padding: 20px;
  height: 100%;
  position: relative;
  border-radius: 20px;
  /* background-color: rgba(38, 40, 61, .05); */
  background-color: rgba(255, 255, 255, .05);
}
.prize-descriptions-col > img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: -15px;
  left: 10px;
}
.prize-descriptions-outer {
  padding: 10px 0;
}
.prize-descriptions-outer svg {
  height: 50px;
  width: 50px;
  position: absolute;
  top: -15px;
  left: 10px;
}
.prize-descriptions-row-header {
  font-family: 'Satoshi-Black';
}
.descriptions-row-header {
  font-family: 'Satoshi-Black';
  color: #F4438C;
  font-size: 30px;
}
.prize-descriptions-row-text {
  font-size: 18px;
}
.register-button:disabled {
  opacity: 0.8;
  cursor: auto;
}
.register-button {
  border: none;
  background-color: #F4438C;
  border-radius: 4px;
  font-size: 24px;
  color: white;
  padding: 15px 80px;
  cursor: pointer;
}
.register-button:hover {
  /* scale: 1.03; */
}
.fpl-chart-tabs > .col {
  padding: 10px;
  cursor: pointer;
  position: relative;
  display: block;
}
/* .fpl-chart-tabs:not(.play-in) > .col.tab-1 {
  border-bottom: 3px solid rgb(79	90	200	);
}
.fpl-chart-tabs.play-in > .col.tab-2 {
  border-bottom: 3px solid rgb(116, 38, 199);
} */

.fpl-chart-tabs > .col.tab-1 {
  background-color: rgba(151, 46, 255, 0.75);
  border-top-left-radius: 4px;
}
.fpl-chart-tabs > .col.tab-2 {
  background-color: rgba(101, 116, 255, 0.75);
  border-top-right-radius: 4px;
}
/* .fpl-chart-tabs > .col.tab-1:not(.active):hover { */
.fpl-chart-tabs > .col.tab-1:hover {
  background-color: rgba(151, 46, 255, 0.65);
}
/* .fpl-chart-tabs {
  background-color: rgba(101, 116, 255, 0.2);
} */
/* .fpl-chart-tabs > .col.tab-2:not(.active):hover { */
.fpl-chart-tabs > .col.tab-2:hover {
  background-color: rgba(101, 116, 255, 0.65);
}
.fpl-bar-chart-outer {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px 15px;
  /* background-color: rgba(); */
  /* background-color: rgba(244, 67, 140, 0.5); */
  /* background-color: rgba(151, 46, 255, 0.3); */
  /* background-color: rgba(101, 116, 255, 0.15); */
  background-color: rgba(101, 116, 255, 0.1);
  border: 3px solid rgba(101, 116, 255, 0.75);
  /* border-top: none; */
  /* background-color: rgb(151, 46, 255); */
  /* background-color: rgb(101, 116, 255); */
  /* background-color: rgb(236, 86, 70); */
}
.fpl-bar-chart-outer.play-in {
  background-color: rgba(151, 46, 255, 0.10);
  border: 3px solid rgba(151, 46, 255, 0.75);
  /* border-top: none; */
}
.fpl-bar-chart {
  width: 100%;
  /* max-height: 500px; */
  padding: 20px 0 10px;
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* position: relative; */
}
.fpl-bar-chart .bar-outer {
  flex: 1;
  position: relative;
  padding: 0.2619%;
  /* position: absolute; */
  bottom: 0;
  width: 4.5%;
  height: 100%;
  /* background-color: blue; */
}
.fpl-bar-chart-outer .bar {
  flex: 1;
  /* position: relative; */
  /* padding: 1%; */
  position: absolute;
  bottom: 0;
  width: 90%;
  /* width: 4%; */
  background-color: #6574FF;
  border-radius: 4px;
}
.fpl-bar-chart-outer.play-in .bar {
  background-color: #972EFF;
}
.fpl-bar-chart-outer .bar:hover {
  scale: 1.01;
  border: 2px solid #EC5646;
}
.fpl-bar-chart-outer.play-in .bar:hover {
  border: 2px solid #F4438C;
}
.fpl-chart-title {
  padding-bottom: 15px;
  letter-spacing: 1px;
  font-size: 22px;
}

 /* oup > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active */

.base-dropdown button.btn
, .base-dropdown button.btn:hover
, .base-dropdown button.btn:active
, .base-dropdown button.btn:focus {
  /* background-color: #F4438C; */
  /* background-color: #972EFF; */
  /* background-color: #6574FF; */
  /* background-color: #EC5646; */
  background-color: transparent;
  color: #EC5646;
  border: none;
}
.top-bar .base-dropdown button.btn
, .top-bar .base-dropdown button.btn:hover
, .top-bar .base-dropdown button.btn:active
, .top-bar .base-dropdown button.btn:focus {
  color: white;
}

.user-row a {
  color: #F4438C;
  color: #972EFF;
  color: #6574FF;
  color: #EC5646;
  text-decoration: none;
}
.user-row a:hover {
  color: #EC5646;
  text-decoration: underline;
}
.user-row .header-row, .score-page .accordion-body > .header-row:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.home-page .home-header .thick {
  border-top: 10px solid white;
  transform: translateY(43%);
}

.home-page .home-header .the {
  font-size: 48px;
  letter-spacing: 6px;
  color: #ccc;
  font-family: 'Satoshi-Regular';
}
.home-page .home-header .bold .letter {
  text-transform: uppercase;
  min-width: 20px;
}
.home-page .home-header .bold {
  text-transform: uppercase;
  font-family: 'Satoshi-Black';
  letter-spacing: 4px;
  /* color: #F4438C;
  color: #972EFF;
  color: #6574FF;
  color: white; */
  /* background: -webkit-linear-gradient(#F4438C, #972EFF); */
  /* background: -webkit-linear-gradient(#F4438C, #972EFF, #6574FF, #EC5646); */
  /* background: -webkit-linear-gradient(#6574FF, #EC5646); */
  /* background: -webkit-linear-gradient(left, #F4438C, #972EFF, #6574FF, #EC5646);
  background: -o-linear-gradient(right, #F4438C, #972EFF, #6574FF, #EC5646);
  background: -moz-linear-gradient(right, #F4438C, #972EFF, #6574FF, #EC5646);
  background: linear-gradient(to right, #F4438C, #972EFF, #6574FF, #EC5646);  */
  background: -webkit-linear-gradient(left, #F4438C, #972EFF);
  background: -o-linear-gradient(right, #F4438C, #972EFF);
  background: -moz-linear-gradient(right, #F4438C, #972EFF);
  background: linear-gradient(to right, #F4438C, #972EFF); 
  /* background-image: linear-gradient(to right, rgb(120	169	189	) , rgb(183	225	139	)); */
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */

/* background-color: #F4438C; */
/* background-color: #972EFF; */
/* background-color: #6574FF; */
/* background-color: #EC5646; */

  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.home-page .hero {
  padding: 2% 4% 2%;
  font-size: 72px;
  background-color: #6574FF;
  background-color: #07071b;
}
.sub-header {
  font-size: 30px;
  letter-spacing: 2px;
}
.home-page .home-sub-header {
  padding-top: 30px;
  font-size: 18px;
  color: black;
  color: white;
}
.top-bar .base-dropdown {
  letter-spacing: 2px;
  color: white;
}
.fpl-col {
  display: inline-block;
  padding: 10px 2%;
}
.App .top-bar {
  z-index: 100;
  position: fixed;
  width: 100%;
  border: none;
  height: 54px;
  padding: 5px 30px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  background-color: #07071b;
  color: white;
}
.App .top-bar a.active {
  color: #daa8ff;
}
.App .top-bar a {
  color: white;
  text-decoration: none;
  letter-spacing: .03em;
}
.App .top-bar .base-dropdown-menu a {
  color: black;
}
.App .top-bar a:hover {
  /* color: #972EFF; */
  /* color: #a74cff; */
  color: #b565ff;
  text-decoration: none;
}
.fpl-container {
  /* padding: 0 100px; */
  padding: 80px 0 40px;
  max-width: 1200px;
  margin: auto;
}
.base-dropdown .dropdown-toggle::after {
  margin-left: 10px;
}
.no-team-scores {
  font-size: 20px;
  padding: 40px;
}
.score-page .dropdown-row {
  height: 65px;
}
.fpl-accordion {
  color: white;
  background-color: rgba(255, 255, 255, 0.02);
}
.fpl-accordion .accordion-body {
  color: rgba(255, 255, 255, .75);
  background-color: rgba(255, 255, 255, 0.05);
  line-height: 1.9;
}
.fpl-accordion .accordion-header:hover {
  background-color: rgba(151, 46, 255, 0.05);
}
.fpl-accordion .accordion-button {
  letter-spacing: 1px;
  font-family: 'Satoshi-Regular';
  color: #972EFF;
}
.fpl-accordion .accordion-item {
  background-color: inherit;
  border: 2px solid rgba(255, 255, 255, 0.03);
}
/* .fpl-accordion .accordion-item:first-of-type .accordion-button { */
.fpl-accordion .accordion-item .accordion-button {
  background-color: inherit;
  /* background-color: rgba(255, 255, 255, 0.1); */
}
.fpl-accordion .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.fpl-accordion .accordion-button:not(.collapsed)::after {
  color: white;
}
.fpl-accordion .accordion-button:not(.collapsed) {
  /* background-color: rgba(255, 255, 255, 0.1); */
  background-color: #972EFF;
  color: white;
  box-shadow: none;
}
.fpl-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.fpl-accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23daa8ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.fpl-accordion-icon {
  padding-right: 15px;
}
.faq-page {
  padding-top: 5px;
}
.App a {
  color: #F4438C;
  text-decoration: none;
}
.App a:hover {
  color: #F4438C;
  text-decoration: underline;
}
.code {
  background-color: rgba(0, 0, 0, .2);
  padding: 2px 5px;
}
.fpl-button.btn-primary {
  background-color: #972EFF;
  border: none;
}
.fpl-button.btn-primary:hover, .fpl-button.btn-primary:disabled {
  background-color: rgba(151, 46, 255, 0.50);
}
.participants-panel {
  max-width: 700px;
  border-radius: 4px;
  margin: auto;
  padding: 20px;
}
.participants-panel-header {
  /* border: 2px solid rgba(255, 255, 255, .02); */
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  /* border: none; */
}
.participants-panel-body {
  background-color: rgba(255, 255, 255, .02);
}
.participants-panel-body > .row:hover {
  background-color: rgba(255, 255, 255, .03);
}
.participants-panel-body > .row {
  padding: 5px 0;
}
.participants-panel-body > .row a {
  color: #daa8ff;
}
.participants-panel-body > .row a:hover {
  color: #972EFF;
}
.cal-day {
  font-family: 'Satoshi-Black';
  font-size: 48px;
}
.cal-weekday {
  font-family: 'Satoshi-Regular';
}
.cal-month {
  font-family: 'Satoshi-Light';
  opacity: 0.6;
}
.schedule-page .fpl-col.cal-right {
  line-height: 1.5;
}
.schedule-page .fpl-col {
  padding: 5px;
  line-height: 1;
}
.schedule-page .fpl-col.cal-dotted {
  padding: 0;
}
.schedule-page {
  line-height: 1;
}
.cal-title {
  color: #F4438C;
  color: #972EFF;
  color: #6574FF;
  color: #EC5646; 
  letter-spacing: 1px;
  padding: 10px 0;
}
.cal-outer {
  margin: auto;
  border: 1px solid rgba(255, 255, 255, .15);
  padding: 10px;
  border-radius: 4px;
  width: 200px;
}
.cal-line-outer {
  width: 100%;
  /* padding: 50px 0; */
  padding: 0 0 2px;
}
.cal-line {
  height: 10px;
  background-color: #F4438C;
  background-color: #972EFF;
  background-color: #6574FF;
  background-color: #EC5646;
}
.cal-container {
  width: 25%;
}
.cal-dotted.cal-dotted-1 {
  width: 1px;
}
.fpl-col.cal-container {
  padding: 0 0 2px;
}
.cal-bottom .fpl-col.cal-container {
  padding: 0 0 0;
}
.cal-dotted {
  padding: 0;
  width: 25%;
  border-right: 2px dashed #EC5646;
  /* border-right: 2px solid #EC5646; */
  /* border-right: 2px double #EC5646; */
  height: 50px;
}
@media (max-width: 767px) {
  .fpl-accordion .accordion-button {
    padding: 16px 10px;
  }
  .fpl-accordion .accordion-button .user-col {
    padding: 0;
    /* margin: 0; */
  }
  .fpl-accordion .accordion-button .user-col .avatar {
    height: 35px;
    width: 35px;
  }
  .home-page .home-header .the {
    font-size: 24px;
    white-space: nowrap;
  }
  .home-page .home-header .thick {
    border-top: 2px solid white;
    transform: translateY(43%);
  }
  .home-page .home-header .bold {
    font-size: 32px;
  }
  .prize-page {
    padding: 0 10px;
  }
  .prize-descriptions-row > div {
    padding: 20px 0;
  }
  .faq-page {
    padding: 0 0 0 10px;
  }
  .cal-outer, .cal-container {
    width: 100%;
  }
  .cal-day {
    font-size: 32px;
  }
  .cal-right {
    font-size: 12px;
  }
  .cal-container .fpl-col {
    padding: 2px;
  }
  .cal-container .cal-right {
    padding: 0 0 0 10px;
  }
  .cal-container {
    padding: 15px 5px;
  }
  .cal-title {
    font-size: 12px;
    padding: 0;
  }
  .cal-line {
    height: 100%;
    width: 10px;
  }
}
.scatter-outer {
  position: relative;
  height: 100%;
  width: 2.5%;
}
.scatter:hover {
  border: 2px solid #EC5646;
  scale: 1.05;
}
.scatter {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #6574FF;
  cursor: pointer;

  /* background-color: #F4438C;
  background-color: #972EFF;
  background-color: #6574FF;
  background-color: #EC5646; */
}
.fpl-chart-subtitle {
  font-size: 12px;
  opacity: 0.8;
}
.orange-text {
  color: #EC5646;
}
.pyramid > div {
  font-size: 14px;
  margin: auto;
}
.pyramid-col {
  display: inline-block;
  padding: 10px;
}
.pyramid-text {
  opacity: 0.8;
}
.width-100 {
  width: 100%
}
.padding-top-10 {
  padding-top: 10px;
}
.about-header {
  font-family: 'Satoshi-Bold';
  font-size: 50px;
  text-align: center;
  padding: 40px 0 60px;
  text-transform: uppercase;
  font-weight: 700;
}
.about-description {
  text-align: left;
  line-height: 1.9;
  font-size: 20px;
}